/*
    NOW PROCESSING

*/

main {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 0.5rem;
    width: auto;
    min-width: 100rem;
    max-width: 126rem;
    height: auto;
    border-top: 12.5rem solid transparent;
  }
  main section {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .check {
    position: relative;
    padding: 3rem 3rem 4rem 3rem;
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    margin-top: 3rem;
  }
  .check h1 {
    position: relative;
    font-size: 2rem;
    font-weight: 200;
    color: var(--t-color-point);
    line-height: 3rem;
    text-align: center;
  }
  .check h2 {
    position: relative;
    font-size: 3.5rem;
    font-weight: 100;
    color: rgba(255, 255, 255, 1);
    line-height: 3.5rem;
    letter-spacing: -1px;
    text-align: center;
    text-transform: uppercase;
  }
  .check h3 {
    position: relative;
    margin: 2.3rem 0 0 0;
    font-size: 1.5rem;
    font-weight: 400;
    color: #cccc;
    letter-spacing: 10px;
    text-align: center;
  }
  .check .wrapper {
    position: relative;
    margin: 0 auto -2rem;
    width: 18rem;
    height: 18rem;
    transform: scale(0.5);
  }
  .check .wrapper div {
    position: absolute;
    background: no-repeat 30% 30% / cover url("../images/under_img_gear.svg");
  }
  .check .wrapper div:nth-of-type(1) {
    left: 1rem;
    top: 1rem;
    width: 16rem;
    height: 16rem;
    z-index: 1;
    animation: turn 10s linear infinite;
  }
  .check .wrapper div:nth-of-type(2) {
    right: 1rem;
    bottom: 1rem;
    width: 6rem;
    height: 6rem;
    opacity: 0.5;
    animation: turn 4s linear infinite reverse;
  }
  
  @keyframes turn {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*  mobile  */
  @media (min-width: 320px) and (max-width: 675px) {
    main {
      padding: 4rem 0 0 0;
      width: 100vw;
      height: auto;
      min-width: unset;
      max-width: unset;
      border-top: 0;
    }
    main section {
      position: relative;
      width: 100%;
      height: auto;
    }
  
    .check {
      padding: 0 0 2rem;
      border-radius: 0;
    }
    .check h1 {
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: -1px;
    }
    .check h2 {
      font-size: 2.5rem;
      line-height: 2.5rem;
      letter-spacing: 0;
    }
    .check h3 {
      margin: 3rem 0 0 0;
      font-size: 1rem;
      letter-spacing: 5px;
    }
    .check .wrapper {
      margin: 0 auto -2rem;
      transform: scale(0.5);
    }
  }
  