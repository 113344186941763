/*
    FAQ

*/

main {
   display: flex;
   flex-wrap: wrap;
   margin: 0 auto;
   padding: 0 4.5rem 12rem;
   width: auto;
   min-width: 100rem;
   max-width: 126rem;
   height: auto;
   border-top: 12.5rem solid transparent;
   overflow: hidden;
   font-family: 'Poppins';
 }
 main section {
   position: relative;
   width: 100%;
   height: auto;
 }
 
 section.title {
   position: relative;
   display: flex;
   align-items: center;
   margin: 0 0 1rem 0;
   padding: 0 2rem;
   width: 100%;
   height: 5rem;
   justify-content: center;
 }
 
 section.title * {
   position: relative;
   margin: 0 2rem 0 0;
 }
 section.title h1 {
   margin: 0 auto;
   width: max-content;
   height: 2.75rem;
   font-size: 2.75rem;
   font-weight: 300;
   color: #75ff8c !important;
   line-height: 2.25rem;
 }
 
 .faq {
   position: relative;
   padding: 2rem 2rem 4rem 2rem;
   width: 70%;
   height: auto;
   min-height: 60vh;
   border-radius: 0.5rem;
   margin: 0 auto;
 }
 .faq .q {
   position: relative;
   padding: 2rem 0;
   width: 100%;
   height: auto;
   font-size: 1.3rem;
   font-weight: 400;
   color: var(--t-color-point);
   transition: padding 0.5s, background-color 0.5s;
 }
 .faq .q:hover {
   cursor: pointer;
   padding-left: 1rem;
   background-color: var(--point-shadow);
 }
 .faq .q::before {
   content: "Q";
   margin: 0 1rem 0 0;
   font-weight: 400;
   color: var(--t-color-point);
 }
 .faq .q::after {
   content: "";
   position: absolute;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 1px;
   background-color: var(--t-color-point);;
 }
 .faq .a {
   position: relative;
   padding: 1.5rem 1rem 1.5rem 2rem;
   width: 100%;
   height: auto;
   max-height: 100rem;
   font-size: 1rem;
   font-weight: 400;
   color: #666;
   line-height: 1.8rem;
   overflow-x: hidden;
   overflow-y: auto;
   transition: padding 1s ease, max-height 1s ease;
 }
 .faq .a.collapsed {
   padding: 0 1rem 0 2rem;
   max-height: 0;
   overflow-x: hidden;
   overflow-y: hidden;
 }
 .faq h2 {
   position: relative;
   margin: 0 0 1.3rem 0;
   font-size: 1.2rem;
   font-weight: 300;
   color: var(--t-color-point);
 }
 .faq p {
   position: relative;
   margin: 0 0 1.5rem 0;
   font-weight: 300;
   color: #ffffff;
 }
 .faq ol {
   position: relative;
   margin: 0 0 1.5rem 0;
   padding: 0 0 0 1.5rem;
   line-height: 1.8rem;
 }
 .faq ul {
   position: relative;
   margin: 0 0 3rem 0;
   padding: 0 0 0 1.5rem;
   line-height: 1.9rem;
   list-style-type: disc;
   font-weight: 300;
 }
 .faq .red {
   color: var(--red) !important;
 }
 .faq h3 {
   position: relative;
   margin: 0 0 1.5rem 0;
   padding: 1rem;
   width: 100%;
   height: auto;
   font-size: 1.2rem;
   font-weight: 700;
   color: rgba(255, 255, 255, 1);
   background-color: rgba(255, 255, 255, 0.1);
 }
 .faq table {
   position: relative;
   margin: 0 0 1rem 0;
   width: 100%;
   border-collapse: collapse;
   border: 1px solid rgba(255, 255, 255, 0.1);
   text-align: center;
 }
 .faq table thead th {
   padding: 1rem;
   border: 1px solid rgba(255, 255, 255, 0.1);
   font-weight: 500;
 }
 .faq table tbody td {
   padding: 1rem;
   border: 1px solid rgba(255, 255, 255, 0.1);
 }
 
 /*  mobile  */
 @media (min-width: 320px) and (max-width: 675px) {
   main {
     padding: 4rem 0 0 0;
     width: 100vw;
     height: auto;
     min-width: unset;
     max-width: unset;
     border-top: 0;
   }
   main section {
     position: relative;
     width: 100%;
     height: auto;
   }
 
   section.title {
     padding: 1rem;
     border-radius: 0;
     margin-top: 1rem;
   }
   section.title h1 {
     font-size: 2rem;
     letter-spacing: -2px;
     white-space: nowrap;
   }
   .faq h2 {font-size: 1.05rem; ;}
   .faq {
     padding: 0;
     width: 85%;
   }
   .faq .q {
     padding: 1rem 0;
     font-size: 1rem;
   }
   .faq .a {
     padding: 1.5rem 1rem;
     font-size: 1rem;
   }
   .faq .a.collapsed {
     padding: 0 1rem 0 2rem;
     max-height: 0;
   }
 
   .faq p {
     margin-bottom: 1rem;
     font-size: 14px;
     line-height: 1.6rem;
   }
   .faq ul {
     line-height: 22px;
     font-size: 13px;
   }
 }
 