.modal-leverage {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 500;
}
.modal-leverage .inner {
  position: relative;
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  width: 30rem;
  height: auto;
  background-color: var(--t-color-darkgray);
  border-radius: 1rem;
}
.modal-leverage .inner::after {
  content: "Leverage";
  position: absolute;
  left: 1.5rem;
  top: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}
.modal-leverage .inner a {
  position: absolute;
  right: 1.25rem;
  top: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  transition: color 1s ease;
}
.modal-leverage .inner a:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}
.modal-leverage .inner h3 {
  padding: 0 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}
.modal-leverage .inner .adjust {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem auto 1rem;
  padding: 0 1rem;
  width: 100%;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
}
.modal-leverage .inner .adjust button {
  position: relative;
  width: 3rem;
  height: 3rem;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.5);
  transition: color 1s ease;
}
.modal-leverage .inner .adjust button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}
.modal-leverage .inner .adjust input[type="text"] {
  position: relative;
  width: 10rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
  text-align: center;
}
.modal-leverage .inner .btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}
.modal-leverage .inner .btn button {
  position: relative;
  width: 18%;
  height: 2rem;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  transition: background-color 1s ease;
}
.modal-leverage .inner .btn button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-leverage .inner h2 {
  margin: 1rem auto 2rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}
