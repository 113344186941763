.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.image-container {
  width: 100%;
  max-width: 600px; /* 원하는 최대 너비 */
  height: auto;
  aspect-ratio: 16 / 9; /* 원하는 가로세로 비율 */
  overflow: hidden;
  position: relative;
}

.responsive-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지가 영역을 완전히 덮도록 조정 */
  object-position: center; /* 이미지 중심을 기준으로 조정 */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
