@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --t-color-point: #6666ff;
  --t-color-light-point: #8888ff;
  --t-color-desc: #777E90;
  --t-color-red: #FC3044;
  --t-color-green: #32CC86;
  --t-color-darkred: #8B0000;
  --t-color-darkgreen: #006400;
  --t-color-darkgray: #1c1c1c;
  --t-color-text: rgba(0, 0, 0, 0.87);
  --t-color-white: #fff;
  --t-color-black: #000;
  --t-color-gray-400: #929292;
  --t-color-gray-500: #6e6e6e;
  --t-color-gray-600: #545454;
  --t-color-gray-800: #1a1a1a;
  --t-color-unselected-color: #8C8C8C;
  --t-color-footer: rgba(10, 10, 10, 1);
  --t-color-7: rgba(245, 245, 245, 0.7);
  --t-color-10: rgba(245, 245, 245, 1);
  --t-color-disable: #909090;
  
}

@layer components {
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Montserrat",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--t-color-black);
}

#root {
  width: 100vw;
  height: 100vh;
}

input, textarea {
  -webkit-appearance: none;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
