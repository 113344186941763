/*  WELCOME */
article.welcome { display: flex; justify-content: left;  width: 100%; height: 45rem; background: no-repeat 80% 50% / auto 30rem rgba(0, 0, 0, 0.1) url('../images/home_bg.png'); }
article.welcome section { position: relative; width: 100%; max-width: 70%; padding: 10rem; height: auto; }
article.welcome section h1 { font-size: 3rem; font-variation-settings: 'wght' 600; color: var(--t-color-10); line-height: 4rem; white-space: wrap; }
article.welcome section h2 { margin-top: 5rem; font-size: 1.3rem; font-weight: 600; color: var(--t-color-10); line-height: 3rem; white-space: wrap; }
article.welcome section h1 strong { font-variation-settings: 'wght' 900; color: var(--t-color-point); text-transform: uppercase; }
article.welcome section h2 strong { font-variation-settings: 'wght' 900; color: var(--t-color-point); text-transform: uppercase; }
article.welcome section h1 span { display: block; line-height: 4rem; }
article.welcome section h2 span { display: block; line-height: 2rem; }
article.welcome a { display: flex; justify-content: center; align-items: center; margin: 4rem 0 0 0; padding: 0 3rem; width: max-content; height: 4rem; font-size: 1.5rem; font-variation-settings: 'wght' 600; color: var(--t-color-white); letter-spacing: 0.2rem; text-transform: uppercase; background-color: var(--t-color-point); border-radius: 2rem; transition: padding 0.4s ease, box-shadow 0.4s ease; }
article.welcome a:hover { padding: 0 3rem; box-shadow: 0 0 3rem  var(--t-color-point)  }
 
/*  FEATURES    */
div.features { position: relative; padding: 0 180px; } 
article.features { display: flex; flex-wrap: wrap; justify-content: space-between; align-content: flex-start; margin: 0 auto; padding: 3rem 0; width: 100%; }
article.features h2 { display: block; margin: 0 auto 2rem; color: var(--t-color-10); }
article.features section { position: relative; padding: 14rem 2rem 3rem 2rem; width: 24%; font-size: 1.2rem; font-variation-settings: 'wght' 500; color: var(--t-color-10); background-color: rgba(255, 255, 255, 0.05); line-height: 1.5rem; border-radius: 2rem; overflow: hidden; }
article.features section::before { content: ''; position: absolute; display: block; left: 0; top: 0; width: 100%; height: 12rem; background: no-repeat 50% 50% / cover; }
article.features section.charts::before { background-image: url('../images/home_img_chart.jpg'); }
article.features section.security::before { background-image: url('../images/home_img_security.jpg'); }
article.features section.platform::before { background-image: url('../images/home_img_easy.jpg'); }
article.features section.support::before { background-image: url('../images/home_img_cs.jpg'); }
article.features section h3 { position: absolute; display: flex; justify-content: center; align-items: center; left: 0; top: 0; width: 100%; height: 12rem; font-size: 1.5rem; font-variation-settings: 'wght' 700; color: var(--c-white); background-color: rgba(0, 0, 0, 0.5); text-align: center; }

/* TRADING */
div.trading { position: relative; padding: 0 180px; } 
article.trading { display: flex; flex-wrap: wrap; justify-content: space-between; align-content: flex-start; margin: 0 auto; padding: 1rem 0; width: 100%; }
article.trading h2 { margin: 0 auto 2rem; }
article.trading section { display: flex; flex-wrap: wrap; align-content: center; padding: 2rem 2rem 2rem 12rem; width: 49.5%; height: 18rem; font-size: 1.8rem; font-variation-settings: 'wght' 500; color: var(--t-color-7); background: no-repeat 4rem 50% / 4rem 4rem rgba(0, 0, 0, 0.2); border-radius: 2rem; }
article.trading section:nth-of-type(1) { background-image: url('../images/home_icon_bit.svg'); }
article.trading section:nth-of-type(2) { background-image: url('../images/home_icon_opt.svg'); }
article.trading section h2 { width: 100%; font-variation-settings: 'wght' 700; color: var(--t-color-10); }
article.trading section h3 { width: 100%; font-size: 1.2rem; font-variation-settings: 'wght' 500; color: var(--t-color-7); }
article.trading section a { display: flex; margin: 1rem 0 0 0; width: max-content; height: 2rem; align-items: center; font-size: 1.4rem; font-variation-settings: 'wght' 600; color: var(--t-color-point); }
article.trading section a::after { content: ''; display: block; margin: 0 0 0 1rem; width: 1.2rem; height: 1.2rem; background-color: var(--t-color-point); -webkit-mask: no-repeat 50% 50% / contain url('../images/sys_icon_go.svg'); mask: no-repeat 50% 50% / contain url('../images/sys_icon_go.svg'); }

/* CONTRACTS  */
div.contracts { position: relative; padding: 0 180px; } 
article.contracts { margin: 0 auto; padding: 1rem 0 5rem; width: 100%; }
article.contracts :is(h2, h3) { margin: 0 auto; width: 100%; }
article.contracts h3 { margin: 1rem auto 0; font-size: 1.5rem; font-variation-settings: 'wght' 500; color: var(--t-color-10); line-height: 2rem; }


/*  MOBILE  */
@media (min-width: 320px) and (max-width: 1024px) {
  article.welcome { align-items: flex-start; padding: 7rem 0 3rem 0; height: 30rem; background: no-repeat 46vw 12rem / contain rgba(0, 0, 0, 0.1) url('../images/home_bg.png'); }
  article.welcome section { width: 100%; max-width: unset; padding: 0 2rem; }
  article.welcome section h1 { font-size: 2rem; line-height: 2.5rem; }
  article.welcome section h2 { margin-top: 1rem; width: 100%; font-size: 1rem; line-height: 1.5rem; }
  article.welcome section h1 span { line-height: 2.5rem; }
  article.welcome section h2 span { line-height: 1.5rem; }
  article.welcome a { margin: 4rem 0 0 0; height: 3rem; font-size: 1.2rem; letter-spacing: 0.2rem; border-radius: 2rem; }

  div.features { position: relative; padding: 0 3rem; margin-top: 3rem; } 
  article.features { margin: 0 auto; min-width: unset; max-width: unset; }
  article.features h2 { margin: 0 auto 2rem; }
  article.features section { margin: 0 0 2rem 0; padding: 8rem 2rem 3rem 2rem; width: 100%; height: max-content; font-size: 1.2rem; line-height: 1.7rem; }
  article.features section::before { content: ''; position: absolute; display: block; left: 0; top: 0; width: 100%; height: 5rem; background: no-repeat 50% 50% / cover; }
  article.features section h3 { position: absolute; display: flex; justify-content: center; align-items: center; left: 0; top: 0; width: 100%; height: 5rem; font-variation-settings: 'wght' 700; color: var(--c-white); background-color: rgba(0, 0, 0, 0.5); text-align: center; }

  div.trading { position: relative; padding: 0 3rem; margin-top: -2rem; } 
  article.trading { flex-direction: column; padding: 1rem 2rem; min-width: unset; max-width: unset; }
  article.trading section { align-content: flex-start; padding: 4.5rem 0 0 0; width: 100%; height: auto; margin-top: 1rem; font-size: 1.8rem; background: no-repeat 0 0 / 4rem 4rem rgba(0, 0, 0, 0); }
  article.trading section h2 { margin: 0 auto 0.5rem; width: 100%; font-size: 1.5rem; }
  article.trading section h3 { font-size: 0.9rem; }
  article.trading section a { height: 2rem; font-size: 1.3rem; }
  article.trading section a::after { margin: 0 0 0 1rem; width: 1.2rem; height: 1.2rem; }

  div.contracts { position: relative; padding: 0 3rem; margin-top: 3rem; } 
  article.contracts { padding: 0rem 0rem 5rem 0rem; }
  article.contracts :is(h2, h3) { min-width: unset; max-width: unset; }
  article.contracts h3 { margin: 2rem auto 0; font-size: 1rem; line-height: 1.5rem; }

}