/*
input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
*/

.checkmark {
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #000;
  border-width: 1px;
  border-color: #fff;
  border-radius: 4px;
}

input:checked ~ .checkmark {
  background-color: #6666ff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 6px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.label-text {
  color: white;
  margin-left: 5px;
 font-size: 13px;
}
