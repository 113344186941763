.content-top {
  margin-top: 1rem;
}
.content-padding {
  padding: 0 60px;
}
.content-inner {
  width: 60%;
}
.control-width {
  width: 40%;
}
.title-width {
  width: 20%;
}
.flex-justify {
  justify-content: flex-start;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #333;
  outline: none;
  position: relative;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 2px solid #333;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -8px; /* Adjust to align the thumb */
}

.slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #fff;
  border: 2px solid #333;
  border-radius: 50%;
  cursor: pointer;
}

.slider-step {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #333;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.slider-step.active {
  background: #fff;
  border: 2px solid #333;
}

/*  MOBILE  */
@media (min-width: 320px) and (max-width: 1024px) {
  .content-top {
    margin-top: 5rem;
  }
  .content-padding {
    padding: 0 30px;
  }
  .content-inner {
    width: 100%;
  }
  .control-width {
    width: 100%;
  }
  .title-width {
    width: 60%;
  }
  .flex-justify {
    justify-content: space-between;
  }
}
