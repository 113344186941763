.content-top { margin-top: 1rem; }
.content-padding { padding: 0 60px; }
.content-inner { width: 60%; }
.title-width { width: 20%; }
.flex-justify { justify-content: flex-start; }

/*  MOBILE  */
@media (min-width: 320px) and (max-width: 1024px) {
    .content-top { margin-top: 5rem;  }
    .content-padding { padding: 0 30px; }
    .content-inner { width: 100%; }
    .title-width { width: 60%; }
    .flex-justify { justify-content: space-between; }
}