.manager > * {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}
.manager {
  background: #f7f9fb;
}

:root {
  --t-color-point: #6666ff;
  --blue: rgba(12, 112, 245, 1); /*rgba(30, 73, 138, 1);*/
  --blue-hover: rgba(0, 0, 0, 1); /*rgba(30, 73, 138, 0.75);*/
  --red: rgba(184, 59, 40, 1);
  --yellow: rgba(12, 112, 245, 0.8);
  --point: rgba(14, 77, 142, 1);
  --point-hover: rgba(10, 92, 175, 1);
  --point-shadow: rgba(14, 77, 142, 0.1);
  --point-shadow-3: rgba(14, 77, 142, 0.3);
  --point-shadow-5: rgba(14, 77, 142, 0.5);
  --point-shadow-7: rgba(14, 77, 142, 0.7);
  --red: rgba(198, 45, 29, 1);
  --red-shadow: rgba(210, 21, 21, 0.2);
  --blue: rgba(117, 255, 140, 1);
  --blue-shadow: rgba(21, 75, 210, 0.2);
  --bg-gray: rgba(245, 245, 245, 1);
  --bg-gray-235: rgba(235, 235, 235, 1);
  --yellow: blanchedalmond;
  --green: rgba(14, 203, 129, 1);
  --white: rgba(255, 255, 255, 1);
}

input,
button,
textarea {
  background-color: transparent;
  border-radius: 0;
}
a {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}
button:hover {
  cursor: pointer;
}
/*input[type=text].date { background: no-repeat 90% 50% / 15% url('/assets/images/sys_img_date.png'); }
select { background: no-repeat 100% 50% / contain url('/assets/images/select_img_down.svg'); appearance: none; }*/
select:hover {
  cursor: pointer;
}

.manager-header {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2rem;
  left: 0;
  top: 0;
  width: 100%;
  height: 5rem;
  background-color: #000;
  z-index: 10;
}
.manager-header::before {
  content: "ViBex";
  position: absolute;
  left: 4rem;
  top: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  letter-spacing: 1px;
  line-height: 1rem;
}
.manager-header * {
  position: relative;
  font-size: 0.9rem;
  font-weight: 400;
  color: #fff;
}
.manager-header div {
  margin: 0 0 0 1rem;
  font-weight: 500;
  color: #fff;
}
.manager-header div.usdt::after {
  content: "USDT";
  margin: 0 0 0 0.25rem;
  font-weight: 400;
  color: #fff;
}
.manager-header div.trx::after {
  content: "TRX";
  margin: 0 0 0 0.25rem;
  font-weight: 400;
  color: var(--yellow);
}
.manager-header div.btc::after {
  content: "BSC";
  margin: 0 0 0 0.25rem;
  font-weight: 400;
  color: var(--yellow);
}
.manager-header div.xrp::after {
  content: "XRP";
  margin: 0 0 0 0.25rem;
  font-weight: 400;
  color: var(--yellow);
}
.manager-header a {
  margin: 0 0 0 1rem;
  transition: color 1s ease;
}
.manager-header a:hover {
  color: rgba(255, 255, 255, 1);
}

.manager-header span {
  color: rgba(255, 0, 0, 1) !important
}
/*
.manager-header a.code::before {
  content: "\24B8";
  margin: 0 0.25rem 0 0;
  color: #121212;
}
.manager-header a.user::before {
  content: "\24C2";
  margin: 0 0.25rem 0 0;
  color: #121212;
}
*/

.manager-header select {
  margin: 0 0 0 2rem;
  padding: 0 0.5rem;
  width: 4rem;
  height: 1.5rem;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(40, 43, 48, 1);
  border-radius: 0.25rem;
}
.manager-header button {
  margin: 0 0 0 1rem;
  padding: 0 1rem;
  width: max-content;
  height: 1.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
  transition: color 1s ease, background-color 1s ease;
}
.manager-header button:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.5);
}

.manager-nav {
  cursor: pointer;
  position: fixed;
  padding: 2rem;
  left: 0;
  top: 5rem;
  width: 16rem;
  height: auto;
  z-index: 9;
}
.manager-nav a {
  cursor: pointer;
  position: relative;
  display: block;
  margin: 0 0 1rem 0;
  width: 100%;
  height: 4rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 4rem;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.manager-nav a::after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  width: 32rem;
  height: 1px;
  color: #0c70f5;
  background: linear-gradient(
    to right,
    rgba(12, 112, 245, 1) 16rem,
    rgba(0, 0, 0, 0.1) 16rem 32rem
  );
  transform: translateX(-16rem);
  transition: transform 0.5s ease;
}
.manager-nav a:hover::after {
  transform: translateX(0);
}
.manager-nav a.selected::after {
  transform: translateX(0);
}

.manager > .main {
  position: relative;
  padding: 8rem 3rem 4rem 20rem;
  width: 100%;
  height: auto;
  max-width: 122rem;
}
.manager > .manager > .main > h1 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  font-size: 1.7rem;
  font-weight: 50;
  color: #121212;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.manager > .main > h1 strong {
  font-size: 1rem;
  font-weight: 400;
  color: #999;
  letter-spacing: 0px;
  padding-top: 10px;
}
.manager > .main > h1 a {
  font-size: 1.25rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.75);
}
.manager > .main > h1 a:hover {
  color: rgba(255, 255, 255, 1);
}
.manager > .main > h1 a::before {
  content: "\21B6";
  margin: 0 0.5rem 0 0;
}

.mrk {
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  color: #666;
  font-size: 14px;
  font-weight: 400;
}

/*  common  */
.manager table {
  position: relative;
  margin: 1.5rem auto;
  width: 100%;
  height: auto;
  text-align: center;
  border-collapse: collapse;
}
.manager table thead th {
  position: relative;
  padding: 1rem 0;
  font-size: 14px;
  font-weight: 400;
  color: #999;
  border-bottom: 1px solid #121212;
}
.manager table tbody td {
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  color: #121212;
  border-bottom: 1px solid #103b17;
}

section.search .form {
  position: relative;
  display: flex;
  align-items: center;
  margin: 2rem 0 0;
  color: #121212;
  border-radius: 12px;
}
section.search .form * {
  position: relative;
  height: 3rem;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 0.5rem;
}
section.search .form input[type] {
  position: relative;
  padding: 0 1rem;
  width: 15rem;
  border: 1px solid #bbb;
  transition: border-color 1s ease;
}
section.search .form input[type]:focus {
  border-color: rgba(255, 255, 255, 0.5);
}
section.search .form input[type]:nth-of-type(1) {
  margin: 0 1rem 0 0;
}
section.search .form input[type]:nth-of-type(2) {
  margin: 0 1rem 0 1rem;
}
section.search .form select {
  margin: 0 0.5rem 0 0;
  padding: 0 1rem;
  width: 15rem;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0;
  color: #fff;
  font-size: 13px;
}
section.search .form button {
  position: relative;
  margin: 0 0 0 1rem;
  width: 8rem;
  color: #fff;
  padding: 0 12px;
  background-color: rgba(12, 112, 245, 1);
  transition: background-color 1s ease;
}
section.search .form button:hover {
  ackground-color: rgba(12, 112, 245, 0.8);
}
section.search .form div {
  width: 70%;
  text-align: right;
  line-height: 3rem;
}

section.tab {
  position: relative;
  display: flex;
  align-items: center;
  margin: 2rem 0 0;
  color: rgba(255, 255, 255, 1);
}
section.tab * {
  position: relative;
  height: 3rem;
  font-size: 1rem;
  font-weight: 400;
  color: #0c70f5;
  border-radius: 0.5rem;
}
section.tab button {
  position: relative;
  margin: 0 0 0 1rem;
  width: 8rem;
  background-color: #fff;
  border: 1px solid #0c70f5;
  transition: background-color 1s ease;
}
section.tab button:hover {
  background-color: #0c70f5;
  color: #fff;
}
section.tab button.selected {
  background-color: #0c70f5;
  color: #fff;
}

section.page {
  position: relative;
  margin: 2rem auto;
  width: 100%;
  height: auto;
  text-align: center;
}
section.page a {
  position: relative;
  margin: 0 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
}
section.page a:hover {
  color: var(--blue);
}
section.page a.selected {
  font-weight: 700;
  color: var(--blue);
  text-decoration: underline;
}
section.page a.prev::before {
  content: "\226A";
  display: inline;
}
section.page a.next::before {
  content: "\226B";
  display: inline;
}

section.dashboard {
  position: relative;
  display: grid;
  margin: 1.5rem auto;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(1fr, auto);
  column-gap: 1rem;
  row-gap: 1rem;
}
section.dashboard.col-3 {
  grid-template-columns: repeat(3, 1fr);
}

section.dashboard .cell {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.5rem 1.2rem;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  transition: background-color 1s ease;
  cursor: pointer;
}
section.dashboard .cell:hover {
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
}

section.dashboard .cell::after {
  content: "";
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: transparent;
  border-radius: 50%;
}
section.dashboard .cell * {
  position: relative;
  width: 100%;
  text-align: center;
}
section.dashboard .cell h2 {
  font-size: 1.8rem;
  font-weight: 500;
  color: #000;
  text-align: left;
  word-break: keep-all;
  line-height: 2.3rem;
}

section.dashboard .cell .two {
  font-size: 1.8rem;
  font-weight: 500;
  color: #121212;
  text-align: center;
  word-break: keep-all;
  line-height: 2.3rem;
}

section.dashboard .cell h3 {
  margin: 0 0 0.5rem 0;
  height: 2rem;
  font-size: 12.5px;
  font-weight: 300;
  color: #121212;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
section.dashboard .cell h3::after {
  content: "";
  position: absolute;
  left: 40%;
  bottom: 0;
  width: 20%;
  height: 1px;
  background-color: transparent !important;
}

section.dashboard .cell .th {
  color: #fff;
  text-align: left;
}

section.dashboard .cell.mail {
  grid-column: auto / span 2;
}
section.dashboard .cell.copy {
  grid-column: auto / span 3;
  padding: 1rem 4rem 1rem 2rem;
}
section.dashboard .cell.total {
  grid-column: auto / span 2;
}
section.dashboard .cell.total h3::after {
  left: 0;
  width: 100%;
}
section.dashboard .cell h4 {
  padding: 4px 4rem 4px 6rem;
  font-size: 1.25rem;
  font-weight: 400;
  color: #121212;
  text-align: right;
}
section.dashboard .cell h4.futures::before {
  content: "Futures";
  position: absolute;
  left: 0;
  color: #666;
}
section.dashboard .cell h4.btc::before {
  content: "BSC";
  position: absolute;
  left: 0;
  color: #666;
}
section.dashboard .cell h4.usdt::before {
  content: "USDT";
  position: absolute;
  left: 0;
  color: #666;
}
section.dashboard .cell h4.btcusdt::before {
  content: "BTCUSDT";
  position: absolute;
  left: 0;
  color: #666;
}
section.dashboard .cell h4.futures::after {
  content: "Futures";
  position: absolute;
  right: 0;
  top: 8px;
  width: 3.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #999;
  text-align: right;
}
section.dashboard .cell h4.btc::after {
  content: "BSC";
  position: absolute;
  right: 0;
  top: 8px;
  width: 3.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #999;
  text-align: right;
}
section.dashboard .cell h4.usdt::after {
  content: "USDT";
  position: absolute;
  right: 0;
  top: 8px;
  width: 3.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #999;
  text-align: right;
}
section.dashboard .cell h4.btcusdt::after {
  content: "BSC";
  position: absolute;
  right: 0;
  top: 8px;
  width: 3.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #999;
  text-align: right;
}
section.dashboard .cell button {
  height: 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #121212;
  text-align: left;
}
section.dashboard .cell.copy button::before {
  content: "Link";
  margin: 0 2rem 0 0;
  font-size: 1.1rem;
  font-weight: 400;
  color: #0c70f5;
  text-transform: uppercase;
  letter-spacing: 5px;
}
section.dashboard .cell.copy button::after {
  content: "COPY";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  width: 7rem;
  height: 100%;
  background-color: #fff;
  border: 1.5px solid #0c70f5;
  border-radius: 0.5rem;
  transition: background-color 1s ease;
  color: #0c70f5;
}
section.dashboard .cell.copy button:hover::after {
  background-color: #0c70f5;
  color: #fff;
}
section.dashboard .cell.info select {
  position: relative;
  width: 30%;
  height: 2.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(21, 26, 33, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
}
section.dashboard .cell.info button {
  position: relative;
  margin: 0 0 0 0.5rem;
  padding: 0 2rem;
  width: max-content;
  height: 2.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  background-color: var(--blue-hover);
  border-radius: 0.5rem;
}
section.dashboard .cell.info button:hover {
  background-color: var(--blue);
}
section.dashboard .cell.info input[type="text"] {
  position: relative;
  width: 50%;
  height: 2.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(21, 26, 33, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
}

section.dashboard .pink {
  background: linear-gradient(45deg, #ea918c 0%, #ec7188 100%);
}
section.dashboard .sky {
  background: linear-gradient(45deg, #6ad6ef 0%, #54c1ee 100%);
}
section.dashboard .green {
  background: linear-gradient(45deg, #77da93 0%, #7dc8a8 100%);
}
section.dashboard .pur {
  background: linear-gradient(45deg, #c56bcc 0%, #aa61f1 100%);
}
section.dashboard .yellow {
  background: linear-gradient(45deg, #e8eead 0%, #ffd85e 100%);
}
section.dashboard .blue {
  background: linear-gradient(45deg, #76abdf 0%, #3a78da 100%);
}
section.dashboard .vio {
  background: linear-gradient(45deg, #b6d7fb 0%, #b9bce9 100%);
}
section.dashboard .pea {
  background: linear-gradient(45deg, #f8b8b0 0%, #fa9492 100%);
}
section.dashboard .orange {
  background: linear-gradient(45deg, #fedf95 0%, #fcb183 100%);
}
section.dashboard .lightgreen {
  background: linear-gradient(45deg, #86f0c1 0%, #8bdde2 100%);
}
section.dashboard .link {
  background-color: white;
}

section.partner {
  position: relative;
  width: 100%;
  height: auto;
  margin: 1.5rem 0;
}
section.partner > h2 {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  width: 100%;
  height: auto;
  font-size: 17px;
  font-weight: 400;
  color: #121212;
  letter-spacing: 1px;
  text-transform: uppercase;
}
section.partner > h2 span {
  font-size: 1rem;
}
section.partner > h2 span::before {
  content: "Total :";
  display: inline;
  margin: 0 0.25rem 0 0;
  color: rgba(255, 255, 255, 1);
}
section.partner select {
  position: relative;
  padding: 0 1rem;
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(21, 26, 33, 1);
  background-color: rgba(255, 255, 255, 1);
}
section.partner input[type="text"] {
  position: relative;
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: background-color 1s ease;
}
section.partner input[type="text"]:focus {
  background-color: rgba(0, 0, 0, 1);
}
section.partner input[type="number"] {
  position: relative;
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: background-color 1s ease;
}
section.partner input[type="number"]:focus {
  background-color: rgba(0, 0, 0, 1);
}
section.partner input[type="number"]::-webkit-outer-spin-button,
section.partner input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
section.partner button {
  position: relative;
  margin-right: 1rem;
  padding: 0 2rem;
  width: max-content;
  height: 3rem;
  font-size: 1rem;
  color: #0c70f5;
  background-color: var(--blue-hover);
  border-radius: 0.5rem;
  transition: background-color 1s ease;
  border: 1px solid #0c70f5;
}
section.partner button:hover {
  background-color: var(--blue);
  color: #000;
}
/*section.partner table a { position: relative; display: block; padding: 0 2rem 0 1rem; width: 100%; height: 3rem; font-size: 1rem; font-weight: 400; color: rgba(0, 0, 0, 1); line-height: 3rem; text-align: left; background: no-repeat 100% 50% / contain url('/assets/images/sys_img_search.svg'); transition: background-color 1s ease; }*/
section.partner table a:hover {
  background-color: var(--blue);
}
section.partner .settlement {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(21, 26, 33, 1);
}

section.user {
  position: relative;
  width: 100%;
  margin-right: 3rem;
}
section.user table thead th {
  padding: 1rem 0;
  font-size: 0.8rem;
  text-transform: uppercase;
}
section.user table tbody td {
  padding: 1rem 0;
  font-size: 0.9rem;
}
section.user table select {
  position: relative;
  padding: 0 1rem;
  width: 90%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(21, 26, 33, 0.6);
  margin-left: 10px;
}

/*
    section.user table a { position: relative; display: block; padding: 0 2rem 0 1rem; 
    width: 100%; height: 3rem; font-size: 1rem; font-weight: 400; color: #666; 
    line-height: 3rem; text-align: left; background: no-repeat 100% 50% / contain url('/assets/images/sys_img_search.svg'); transition: background-color 1s ease; }*/
section.user table a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

section.detail {
  position: relative;
  width: 100%;
  height: auto;
}
section.detail > h2 {
  position: relative;
  margin: 2rem 0 0 0;
  padding: 0 1rem;
  width: 100%;
  height: auto;
  font-size: 1.5rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -1px;
}
section.search .detail::before {
  content: "Total:";
  display: inline;
  margin: 0 1rem 0 0;
  color: rgba(0, 0, 0, 0.75);
}
section.detail table thead th {
  padding: 1rem 0;
  font-size: 0.8rem;
}
section.detail table tbody td {
  padding: 1rem 0;
  font-size: 0.9rem;
}

section.refer {
  position: relative;
  margin-right: 3rem;
  height: auto;
}
section.refer > h2 {
  position: relative;
  margin: 2rem 0 0 0;
  padding: 0 1rem;
  width: 100%;
  height: auto;
  font-size: 1.5rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -1px;
}
section.refer table thead th {
  padding: 1rem 0;
  font-size: 0.8rem;
  text-transform: uppercase;
}
section.refer table tbody td {
  padding: 1rem 0;
  font-size: 0.9rem;
}
section.search .refer::before {
  content: "Total Total:";
  display: inline;
  margin: 0 1rem 0 0;
  color: rgba(0, 0, 0, 0.75);
}

section.withdraw {
  position: relative;
  width: 100%;
  height: auto;
}
section.withdraw h2 {
  position: relative;
  margin: 3rem 0 0 0;
  padding: 0 1rem;
  width: 100%;
  height: auto;
  font-size: 1.5rem;
  font-weight: 400;
  color: #0c70f5;
  text-transform: uppercase;
  letter-spacing: 1px;
}
section.withdraw h4 {
  position: relative;
  margin: 0.5rem 0 2rem 0;
  padding: 0 1rem;
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  color: #121212;
}
section.withdraw table thead th {
  padding: 1rem 0;
  font-size: 0.8rem;
}
section.withdraw table tbody td {
  padding: 1rem 0;
  font-size: 0.9rem;
}

section.withdraw .inner {
  position: relative;
  padding: 2rem 1rem 4rem;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #103b17;
}
section.withdraw .inner h2 {
  position: relative;
  margin: 0 0 2rem 0;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: #0c70f5;
}
section.withdraw .inner h2::before {
  content: "Amount Settlement:";
  display: inline;
  margin: 0 0.5rem 0 0;
  font-weight: 400;
  color: #999;
}
section.withdraw .inner h2::after {
  content: "";
  display: inline;
  margin: 0 0 0 0.25rem;
  font-weight: 400;
  color: #666;
  font-size: 1.2rem;
}
section.withdraw .inner h3 {
  position: relative;
  margin: 0 0 2rem 0;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: #0c70f5;
}
section.withdraw .inner h3::before {
  content: "Amount Available:";
  display: inline;
  margin: 0 0.5rem 0 0;
  font-weight: 400;
  color: #999;
}
section.withdraw .inner h3::after {
  content: "USDT(BEP-20) (Fee: 50 USDT)";
  display: inline;
  margin: 0 0 0 0.25rem;
  font-weight: 400;
  color: #666;
  font-size: 1.2rem;
}
section.withdraw .inner div {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;
  padding: 0 0 0 6rem;
  width: 100%;
  height: auto;
}
section.withdraw .inner div input[type="number"] {
  position: relative;
  margin-left: 20px;
  padding: 0 1rem;
  width: 25rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: 400;
  border-bottom: 1px solid #121212;
  color: #121212;
  transition: background-color 1s ease;
}

section.withdraw .inner div input[type="number"]:focus {
  border-bottom: 1px solid #0c70f5;
  color: #0c70f5;
}

section.withdraw .inner div input[type="text"] {
  position: relative;
  padding: 0 1rem;
  width: 25rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: 400;
  border-bottom: 1px solid #121212;
  color: #121212;
  transition: background-color 1s ease;
}

section.withdraw .inner div input[type="text"]:focus {
  border-bottom: 1px solid #103b17;
  border-bottom: 1px solid #0c70f5;
  color: #0c70f5;
}

section.withdraw .inner div button {
  position: relative;
  margin: 0 0 0 1rem;
  padding: 0 2rem;
  width: max-content;
  height: 3rem;
  font-size: 1rem;
  font-weight: 400;
  background-color: #fff;
  color: #0c70f5;
  border: 1.5px solid #0c70f5;
  transition: background-color 1s ease;
}

section.withdraw .inner div button:hover {
  cursor: pointer;
  background-color: #0c70f5;
  color: #fff;
}

section.withdraw .inner .price::before {
  content: "Withdrawals";
  position: absolute;
  left: 0;
  top: 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  color: #666;
}

section.withdraw .inner .address::before {
  content: "Address";
  position: absolute;
  left: 0;
  top: 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  color: #666;
}
section.withdraw .inner > button {
  position: relative;
  margin: 1rem 0 0 0;
  padding: 0 4rem;
  width: max-content;
  height: 3rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: #0c70f5;
  color: #fff;
  border: 1px solid #0c70f5;
  transition: background-color 1s ease;
}
section.withdraw .inner > button:hover {
  cursor: pointer;
}

section.history {
  position: relative;
  width: 100%;
  height: auto;
}
section.history .summary {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 2.5rem auto;
  padding: 1rem 2rem;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 0.5rem;
}
section.history .summary div {
  position: relative;
  width: 30%;
  height: auto;
  text-align: center;
}
section.history .summary h2 {
  font-size: 1.1rem;
  font-weight: 600;
}
section.history .summary h2 span {
  margin: 0 0.5rem 0 0;
  color: #666;
  font-weight: 400;
}
section.history .summary h3 {
  font-size: 0.8rem;
  font-weight: 500;
  color: #999;
  letter-spacing: 2px;
  text-transform: uppercase;
}
section.history .summary div:nth-of-type(1) h2 {
  color: #0c70f5;
}
section.history .summary div:nth-of-type(2) h2 {
  color: var(--red);
}
section.history .summary div:nth-of-type(3) h2 {
  color: var(--t-color-point);
}
section.history table thead th {
  padding: 1rem 0;
  font-size: 0.8rem;
}
section.history table tbody td {
  padding: 1rem 0;
  font-size: 0.9rem;
}
section.search .history::before {
  content: "Fees Accrued:";
  display: inline;
  margin: 0 0.5rem 0 0;
  color: rgba(0, 0, 0, 0.75);
}
section.search .history::after {
  content: "Futures";
  display: inline;
  margin: 0 0 0 0.5rem;
  color: rgba(0, 0, 0, 0.75);
}

section.refer {
  position: relative;
  width: 100%;
  height: auto;
}
section.refer .summary {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 2.5rem auto;
  padding: 1rem 2rem;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 0.5rem;
}
section.refer .summary div {
  position: relative;
  width: 30%;
  height: auto;
  text-align: center;
}
section.refer .summary h2 {
  font-size: 1.1rem;
  font-weight: 600;
}
section.refer .summary h2 span {
  margin: 0 0.5rem 0 0;
  color: #666;
  font-weight: 400;
}
section.refer .summary h3 {
  font-size: 0.8rem;
  font-weight: 500;
  color: #999;
  letter-spacing: 2px;
  text-transform: uppercase;
}
section.refer .summary div:nth-of-type(1) h2 {
  color: #0c70f5;
}
section.refer .summary div:nth-of-type(2) h2 {
  color: var(--red);
}
section.refer .summary div:nth-of-type(3) h2 {
  color: #000;
}
section.refer .summary div:nth-of-type(4) h2 {
  color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.find {
  position: relative;
  width: 80%;
  margin: 4rem auto;
  padding: 2rem;
  text-align: center;
}
.find h1 {
  position: relative;
  margin: 0 0 2rem 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #0c70f5;
  text-align: center;
}
.find h2 {
  margin: 1rem 0 0.25rem 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #999;
  text-align: left;
}
.find input[type="text"],
.find input[type="password"],
.find input[type="number"] {
  position: relative;
  padding: 0 0.5rem;
  width: 100%;
  height: 3rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: #121212;
  border-bottom: 1px solid #121212;
  transition: background-color 1s ease;
}
.find input[type="text"]:focus,
.find input[type="password"]:focus,
.find input[type="number"]:focus {
  border-bottom: 1.5px solid #0c70f5;
  color: #0c70f5;
}

.find > button {
  position: relative;
  margin: 2rem 0 0 0;
  width: 100%;
  height: 4rem;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: #0c70f5;
  color: #fff;
  border: 1px solid #0c70f5;
  transition: color 0.5s, box-shadow 0.5s;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.find > button:hover {
  cursor: pointer;
}
.find select {
  position: relative;
  padding: 0 0.5rem;
  width: 100%;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(32, 41, 55, 1);
  transition: background-color 1s ease;
}
.find select:focus {
  background-color: rgba(32, 41, 55, 1);
}
.find .auth {
  position: relative;
  width: 100%;
  height: auto;
}
.find .auth button {
  position: absolute;
  padding: 0 1rem;
  right: 0;
  top: 7px;
  width: max-content;
  height: 2rem;
  font-size: 1rem;
  font-weight: 400;
  background-color: #fff;
  color: #0c70f5;
  border: 1px solid #0c70f5;
  border-radius: 9rem;
  transition: color 0.5s, box-shadow 0.5s;
}
.find .auth button:hover {
  cursor: pointer;
  background-color: #0c70f5;
  color: #000;
}
.find .divide {
  position: relative;
  margin: 2rem auto;
  width: 100%;
  height: 1px;
  font-size: 0;
  background-color: rgba(255, 255, 255, 0.05);
}

.modal-calendar {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  animation: show-calendar 0.5s ease 1 forwards;
}
.modal-calendar .outer {
  position: relative;
  width: 100%;
  height: 100%;
}
.modal-calendar .inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0 2rem;
  width: 30rem;
  height: 100%;
  background-color: rgba(108, 235, 129, 0.95);
}
.modal-calendar .control {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;
  height: auto;
}
.modal-calendar .control button {
  position: relative;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  font-weight: 100;
  color: #000;
  background-color: transparent;
  transition: background-color 1s ease;
}
.modal-calendar .control button:hover {
  background-color: var(--blue);
}
.modal-calendar .control button:nth-of-type(1)::before {
  content: "\226A";
  display: inline;
}
.modal-calendar .control button:nth-of-type(2)::before {
  content: "\226B";
  display: inline;
}
.modal-calendar .control h1 {
  position: relative;
  width: max-content;
  font-size: 2rem;
  font-weight: 600;
  color: #000;
}
.modal-calendar .subtitle {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 1rem 0;
  width: 21rem;
  height: auto;
  border-bottom: 1px solid #000;
}
.modal-calendar .subtitle span {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  font-size: 0.9rem;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.modal-calendar .days {
  position: relative;
  width: 21rem;
  height: auto;
  font-size: 0;
}
.modal-calendar .days a {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 4rem;
  font-size: 1.25rem;
  font-weight: 400;
  color: #000;
  line-height: 4rem;
  text-align: center;
  transition: background-color 0.5s ease;
}
.modal-calendar .days a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.modal-calendar .days a.etc {
  color: rgba(0, 0, 0, 0.25);
}
.modal-calendar .days a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}
@keyframes show-calendar {
  0% {
    transform: translateX(21rem);
  }
  100% {
    transform: translateX(0);
  }
}

@media (min-width: 320px) and (max-width: 675px) {
  body {
    overflow: auto;
  } /*background: linear-gradient(to left, rgba(21, 26, 33, 1), rgba(21, 26, 33, 1)); */
  .manager > .main {
    position: relative;
    padding: 9.5rem 10px 1rem;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    overflow-x: auto;
    overflow-y: auto;
  }

  .manager-header {
    padding: 0 1rem;
  }
  .manager-header::before {
    display: none;
  }
  .manager-header::after {
    content: "L";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 1rem;
    top: 1.75rem;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.8rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    background-color: rgba(255, 255, 255, 0.25);
  }
  .manager-header * {
    font-size: 0.8rem;
  }
  .manager-header div {
    margin: 0 0 0 1.2rem;
  }
  .manager-header a {
    margin: 0 4px;
  }
  .manager-header button {
    margin: 0;
    padding: 0 0.5rem;
    font-size: 0.7rem;
  }

  .manager-nav {
    position: fixed;
    display: flex;
    padding: 0 0 0 1rem;
    left: 0;
    top: 5rem;
    width: 100%;
    height: 2rem;
    background-color: #121212;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 9;
    border-top: 1px solid #103b17;
    border-bottom: 1px solid #103b17;
  }
  .manager-nav::-webkit-scrollbar {
    display: none;
  }
  .manager-nav a {
    color: #fff;
    flex: 1 0 auto;
    display: block;
    margin: 0 1rem 0 0;
    width: max-content;
    height: 2rem;
    font-size: 14px;
    line-height: 2rem;
  }
  .manager-nav a::after {
    display: none;
  }
  .manager-nav a.selected::after {
    display: none;
  }

  .manager-header a {
    line-height: 12px;
  }
  .manager-header a.code::before {
    content: "\24B8\A";
    white-space: pre;
  }
  .manager-header a.user::before {
    content: "\24C2\A";
    white-space: pre;
  }

  .main > h1 {
    height: 5rem;
    font-size: 2rem;
    justify-content: center;
    line-height: 2.2rem;
    text-align: center;
  }
  .main > h1 strong {
    font-size: 0.9rem;
    margin-left: 12px;
  }
  .main > h1 a {
    font-size: 1rem;
  }

  .mrk {
    text-align: center;
    font-size: 12px;
    margin: 0 auto;
    width: 220px;
  }
  section.search {
    width: 100%;
    padding: 0 1rem;
  }
  section.search form {
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: unset;
    justify-content: center;
  }
  section.search form * {
    margin: 0 0 0.5rem 0;
    height: 3rem;
    font-size: 0.9rem;
  }
  section.search form input[type] {
    position: relative;
    padding: 0 1rem;
    width: 9rem;
  }
  section.search form input[type]:nth-of-type(1) {
    margin: 0 0 0.5rem 0;
  }
  section.search form input[type]:nth-of-type(2) {
    margin: 0 1rem 0.5rem 1rem;
  }
  section.search form select {
    width: 6rem;
  }
  section.search form button {
    padding: 0 1rem;
    width: max-content;
  }
  section.search form div {
    width: 100%;
    text-align: center;
    line-height: 3rem;
  }

  section.page {
    width: 80%;
    height: auto;
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
  }
  section.page a {
    font-size: 0.8rem;
    margin: 0 6px;
  }

  section.dashboard {
    padding: 0 1rem;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  section.dashboard.col-3 {
    grid-template-columns: repeat(1, 1fr);
  }
  section.dashboard .cell {
    padding: 2rem 2rem;
    height: 10rem;
  }
  section.dashboard .cell h2 {
    font-size: 1rem;
    word-break: keep-all;
    line-height: 1.3rem;
  }
  section.dashboard .cell h3 {
    margin: 0 0 12px 0;
    height: 2rem;
    font-size: 12px;
  }
  section.dashboard .cell.mail {
    grid-column: auto / span 2;
  }
  section.dashboard .cell.copy {
    grid-column: auto / span 2;
    padding: 1rem 2rem;
  }
  section.dashboard .cell.total {
    grid-column: auto / span 2;
  }
  section.dashboard .cell h4 {
    padding: 0 4rem 0 6rem;
    font-size: 1rem;
  }
  section.dashboard .cell button {
    height: 7rem;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
  }
  section.dashboard .cell.copy button::before {
    content: "LINK\A";
    white-space: pre;
    margin: 0 auto;
    font-size: 1rem;
  }
  section.dashboard .cell.copy button::after {
    content: "주소복사";
    position: relative;
    display: block;
    margin: 0.5rem auto;
    padding: 0.1rem 2rem;
    width: max-content;
    height: auto;
    border-radius: 5rem;
  }
  section.dashboard .cell.info select {
    position: relative;
    width: 30%;
    height: 2.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(21, 26, 33, 1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
  }
  section.dashboard .cell.info button {
    position: relative;
    margin: 0 0 0 0.5rem;
    padding: 0 2rem;
    width: max-content;
    height: 2.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    background-color: var(--blue-hover);
    border-radius: 0.5rem;
  }
  section.dashboard .cell.info button:hover {
    background-color: var(--blue);
  }
  section.dashboard .cell.info input[type="text"] {
    position: relative;
    width: 50%;
    height: 2.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(21, 26, 33, 1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
  }

  section.dashboard .cell h4.futures::after,
  section.dashboard .cell h4.btc::after,
  section.dashboard .cell h4.usdt::after {
    top: 2px;
  }

  section.partner {
    position: relative;
    padding: 0 0 0 1rem;
    width: 100%;
    height: auto;
    overflow-x: auto;
  }
  section.partner table {
    width: 400%;
  }
  section.partner > h2 {
    margin: 2rem 0 0 0;
    padding: 0;
    font-size: 1.1rem;
  }
  section.partner > h2 span {
    padding: 0 1rem 0 0;
    font-size: 0.85rem;
  }
  section.partner button {
    padding: 0 1rem;
  }
  section.user {
    position: relative;
    padding: 0 0 0 1rem;
    width: 100%;
    height: auto;
    overflow-x: auto;
  }
  section.user table {
    width: 400%;
  }

  section.history {
    position: relative;
    width: 100%;
    height: auto;
    overflow-x: auto;
  }
  section.history .summary {
    flex-wrap: wrap;
    margin: 0 auto;
    background: #fff;
    padding: 1.5rem 1rem;
    border: 1px solid #eee;
    width: 90%;
    border-radius: 15px;
  }
  section.history .summary div {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  section.history .summary h2 {
    font-size: 15px;
    margin-top: 3px;
  }
  section.history .summary h3 {
    font-size: 11px;
    letter-spacing: 1px;
    margin-top: 4px;
  }
  section.history .summary div:nth-of-type(1) h2::before {
    content: "유저 수익 총합";
    display: block;
    margin-bottom: 4px;
    color: #121212;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 16px;
  }
  section.history .summary div:nth-of-type(2) h2 {
    color: var(--red);
  }
  section.history .summary div:nth-of-type(2) h2::before {
    content: "유저 손실 총합";
    display: block;
    margin-bottom: 4px;
    color: #121212;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 16px;
  }
  section.history .summary div:nth-of-type(3) h2 {
    color: var(--yellow);
  }
  section.history .summary div:nth-of-type(3) h2::before {
    content: "총 유저 손익";
    display: block;
    margin-bottom: 4px;
    color: #121212;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 16px;
  }

  section.history .summary h2 span {
    font-size: 12px;
    font-weight: 400;
  }
  section.history table {
    width: 400%;
  }
  section.history table thead th {
    padding: 1rem 0;
    font-size: 0.8rem;
  }
  section.history table tbody td {
    padding: 1rem 0;
    font-size: 0.9rem;
  }

  section.refer .summary {
    flex-wrap: wrap;
    margin: 0 auto;
    background: #fff;
    padding: 1.5rem 1rem;
    border: 1px solid #eee;
    width: 90%;
    border-radius: 15px;
  }
  section.refer .summary div {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  section.refer .summary h2 {
    font-size: 15px;
    margin-top: 3px;
  }
  section.refer .summary h3 {
    font-size: 11px;
    letter-spacing: 1px;
    margin-top: 4px;
  }
  section.refer .summary h2 span {
    font-size: 12px;
    font-weight: 400;
  }

  section.refer {
    position: relative;
    padding: 0 0 0 1rem;
    width: 100%;
    height: auto;
    overflow-x: auto;
    overflow-y: auto;
  }
  section.refer table {
    width: 100%;
  }

  section.withdraw {
    position: relative;
    width: 100%;
    height: auto;
    overflow-x: auto;
  }
  section.withdraw h2 {
    position: relative;
    text-align: center;
  }
  section.withdraw h4 {
    padding: 0 3rem;
    font-size: 1rem;
    text-align: center;
  }
  section.withdraw table {
    width: 200%;
  }
  section.withdraw table thead th {
    padding: 1rem 0;
    font-size: 0.8rem;
  }
  section.withdraw table tbody td {
    padding: 1rem 0;
    font-size: 0.9rem;
  }

  section.withdraw .inner {
    margin: 0 auto;
    padding: 1rem;
    border-bottom: 0;
    width: 90vw;
    text-align: center;
  }
  section.withdraw .inner h2 {
    margin: 0 0 2rem 0;
    font-size: 1.1rem;
    text-align: center;
    background: #fff;
    padding: 1rem 8px;
    border-radius: 25px;
  }
  section.withdraw .inner h2::before {
    content: "신청 가능액";
    display: block;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #0c70f5;
  }
  section.withdraw .inner h2::after {
    content: "USDT (출금수수료: 50 USDT)";
    display: inline;
    margin: 0 0 0 0.25rem;
    font-size: 0.9rem;
    font-weight: 400;
  }
  section.withdraw .inner h3 {
    margin: 0 0 2rem 0;
    font-size: 1.1rem;
    text-align: center;
    background: #fff;
    padding: 1rem 8px;
    border-radius: 25px;
  }
  section.withdraw .inner h3::before {
    content: "신청 가능액";
    display: block;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #0c70f5;
  }
  section.withdraw .inner h3::after {
    content: "USDT(BEP-20) (출금수수료: 50 USDT)";
    display: inline;
    margin: 0 0 0 0.25rem;
    font-size: 0.9rem;
    font-weight: 400;
  }
  section.withdraw .inner div {
    flex-wrap: wrap;
    margin: 0 auto 1rem;
    padding: 0;
    width: 90%;
    height: auto;
    justify-content: center;
  }
  section.withdraw .inner div input[type="number"] {
    width: 100%;
    text-align: center;
    font-size: 1.7rem;
    height: 5rem;
    margin: 0 auto;
    position: relative;
  }
  section.withdraw .inner div input[type="text"] {
    width: 100%;
  }
  section.withdraw .inner div button {
    margin: 0 auto;
    position: absolute;
    right: 0;
    height: 4rem;
    bottom: 9px;
    padding: 0 1.5rem;
    border-radius: 0.5rem;
  }
  section.withdraw .inner .price::before {
    content: "출금신청액\A";
    white-space: pre;
    position: relative !important;
    display: block;
    top: unset;
    font-size: 1.1rem;
    font-weight: 500;
    color: #0c70f5;
  }
  section.withdraw .inner .address::before {
    content: "출금주소";
    position: relative !important;
    display: block;
    margin: 0 0 0.5rem;
    top: unset;
    font-size: 1.1rem;
    font-weight: 500;
    color: #0c70f5;
  }
  section.withdraw .inner > button {
    margin: 1rem 0 0 0;
    padding: 0;
    width: 100%;
    height: 4rem;
    letter-spacing: 5px;
    font-size: 1.5rem;
  }

  .find {
    width: 100%;
    margin: 0 auto;
    padding: 1.5rem;
  }

  .find .auth button {
    padding: 0 1rem;
    height: 2rem;
    top: 6px;
    font-size: 13px;
  }
  .find .divide {
    height: 0;
  }

  .manager-header select {
    margin: 0 2px;
    padding: 0 6px;
    font-size: 10px;
  }

  section.tab {
    justify-content: center;
    margin: 2rem auto;
  }
}
