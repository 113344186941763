/*  popup-alert */
.popup-alert {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 500;
  }
  .popup-alert .popup-inner {
    position: relative;
    padding: 3rem;
    width: 25rem;
    height: auto;
    background-color: #fff;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  }
  
  .popup-alert .popup-inner p {
    position: relative;
    padding: 0 0 2rem 0;
    width: 100%;
    height: auto;
    font-size: 1.1rem;
    font-weight: 300;
    color: #121212;
    line-height: 1.75rem;
    text-align: center;
  }
  .popup-alert .popup-inner button {
    position: relative;
    width: 100%;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: var(--t-color-point);
    border: 0;
    border-radius: 0.5rem;
    transition: color 1s ease, background 1s ease;
  }
  .popup-alert .popup-inner button:hover {
    cursor: pointer;
    color: #fff;
    background-color: var(--t-color-point);
  }